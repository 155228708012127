import {mapState} from "vuex";
import Api from '@api';
import {getFormConfig} from "../../configs/lineFormConfig";
import {getTableColumn} from '../../configs/lineTableConfig';
import {getSearchValueList,keyToConfigList, uniqueArr, isCanShowBlock} from '@utils'
import { HEAD_FOOT_CODE,TABLE_COLUMN_COMMON, SET_QUERY_SHOW_KEY_SPLIT } from '@constant'
import habitMixins from '@/mixins/habit.js';
import formTableInitMixins from '@/mixins/formTableInit.js'
import exportEdit from '@/mixins/exportEdit.js';

export default {
  name: 'UPILineDetail',
  mixins: [habitMixins,formTableInitMixins, exportEdit],
  provide() {
    return {
      getTableList: this.getList
    };
  },
  data: function () {
    const dataTableName = "UPI LINE";
    const formConfig = getFormConfig(this).filterFormConfig.filter(item => (item.label && isCanShowBlock('searchInfo', item.showBlock)));
    const tableColumn = getTableColumn(this).tableColumn;
    const tableColumnSearchKey = HEAD_FOOT_CODE.rowCode
    //默认获取头或者公共没有sheetName的数据

    const ALL_SEARCH_ITEM_LIST = JSON.parse(window.sessionStorage.getItem('ALL_SEARCH_ITEM_LIST'))[dataTableName];
    const ALL_TABLE_COLUMNS = JSON.parse(window.sessionStorage.getItem('ALL_TABLE_COLUMNS'))[dataTableName];

    const filterFormConfig = keyToConfigList(ALL_SEARCH_ITEM_LIST, formConfig)
    const tableColumnConfigList = keyToConfigList(ALL_TABLE_COLUMNS, tableColumn, 'tag')

    return {
      formData: {}, //筛选条件
      filterFormConfig,
      conditions: formConfig, // 增加查询条件的值
      importData: [], // table 数据
      tableColumnSearchKey,
      tableColumn,
      tableColumnConfigList,
      headerDataTable:'UPI HEADER',
      lineDataTable:'UPI LINE',
      dataTableName,
      injectData: {},
      dialog: null,
      initFormData:{},
      searchItemList: ALL_SEARCH_ITEM_LIST,
      tableColumnList: ALL_TABLE_COLUMNS,
      selectionList: [],
      rangeSelectionList: [],
    }
  },
  computed:{
    ...mapState({
      currentHeaders:state=>state.headLineSearch.upi.currentHeaders,
    })
  },
  mounted(){
    this.$nextTick(()=>{
      this.getList();
    })
  },
  methods: {
    /**
     * @description 获取列表数据
     */
    async getList(){
      if(this.currentHeaders.length){
        this.$refs.filterForm.formData.PALLET_ID = this.currentHeaders.join(SET_QUERY_SHOW_KEY_SPLIT)
      }
      let CRITERION_LIST = getSearchValueList(this.conditions, this.$refs.filterForm.formData)
      let SHOW_COLUMN_LIST = this.tableColumnConfigList.map(item => item.tag).filter(item=>!TABLE_COLUMN_COMMON.includes(item))
      let EXTRA_COLUMN = ['CLIENT_ID','PALLET_ID','LINE_ID']
      let params = {
        CRITERION_LIST: CRITERION_LIST,
        SHOW_COLUMN_LIST: uniqueArr([...SHOW_COLUMN_LIST,...EXTRA_COLUMN]),
        PAGE_NUM: this.$refs.configTable.page.currentPage,
        PAGE_SIZE: this.$refs.configTable.page.pageSize
      };
      if(this.currentHeaders.length){
        params.CRITERION_LIST.forEach(item => {
          if(item.CONDITION === 'PALLET_ID'){
            if(item.VALUE.includes(SET_QUERY_SHOW_KEY_SPLIT)){
              item.CRITERION_TYPE = "LIST_VALUE"
              item.VALUE = item.VALUE.split(SET_QUERY_SHOW_KEY_SPLIT)
            }
          }
        })
      }
      this.exportParams = params;
      this.initFormData = this.$refs.filterForm.formData;
      const {status,data,message} = await Api.GetUPILineList(params)
      if(status === 200){
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /**
     * @description 表单编辑时候的跳转
     */
    clientEdit({PALLET_ID,CLIENT_ID,LINE_ID}, type) {
      this.dialog = type;
      this.$router.push({
        path:'/UPILineDetail',
        query:{
          PALLET_ID,
          CLIENT_ID,
          LINE_ID,
          _current:this.dataTableName
        }
      })
    },
    /**
     * @description 清除表单和父子间的数据
     */
    dialogClosed(){
      this.dialog = null;
      this.injectData = {};
    },
    /**
     * @description 导出
     */
    handleExport() {
      this.exportDefine('UPI-line 表', 'ExportUPILine')
    },
  }
}