import Api from '@api';
import {getFormConfig} from "../../configs/headFormConfig";
import {getTableColumn} from '../../configs/headTableConfig';
import {getSearchValueList,keyToConfigList, uniqueArr, isCanShowBlock} from '@utils'
import { HEAD_FOOT_CODE,TABLE_COLUMN_COMMON } from '@constant'
import habitMixins from '@/mixins/habit.js';
import formTableInitMixins from '@/mixins/formTableInit.js'
import exportEdit from '@/mixins/exportEdit.js';

export default {
  name: 'UPIHeadDetail',
  components:{  },
  mixins: [habitMixins,formTableInitMixins, exportEdit],
  provide() {
    return {
      getTableList: this.getList
    };
  },
  data: function () {
    const dataTableName = 'UPI HEADER';
    const formConfig = getFormConfig(this).filterFormConfig.filter(item => (item.label && isCanShowBlock('searchInfo', item.showBlock)));
    const tableColumn = getTableColumn(this).tableColumn;
    const tableColumnSearchKey = HEAD_FOOT_CODE.headCode

    const ALL_SEARCH_ITEM_LIST = JSON.parse(window.sessionStorage.getItem('ALL_SEARCH_ITEM_LIST'))[dataTableName];
    const ALL_TABLE_COLUMNS = JSON.parse(window.sessionStorage.getItem('ALL_TABLE_COLUMNS'))[dataTableName];

    const filterFormConfig = keyToConfigList(ALL_SEARCH_ITEM_LIST, formConfig)
    const tableColumnConfigList = keyToConfigList(ALL_TABLE_COLUMNS, tableColumn, 'tag')

    return {
      dialog: null,
      formData: {}, //筛选条件
      importData: [], // table 数据
      filterFormConfig,
      conditions: formConfig, // 增加查询条件的值
      tableColumnSearchKey,
      tableColumn, // 原本的表格配置
      tableColumnConfigList,
      headerDataTable:'UPI HEADER',
      lineDataTable:'UPI LINE',
      dataTableName,
      injectData: {},
      checkedList:[],
      initFormData:{},
      searchItemList: ALL_SEARCH_ITEM_LIST,
      tableColumnList: ALL_TABLE_COLUMNS,
      selectionList: [],
      rangeSelectionList: [],
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.$store.commit('SET_CURRENT_HEADERS',{model:'upi',header:[]})
      // this.getList();
    })
  },
  methods: {
    getLineList(){
      let ORDER_LIST = this.checkedList.map(item=>item.PALLET_ID)
      this.$store.commit('SET_CURRENT_HEADERS',{model:'upi',header:ORDER_LIST})
      this.$store.commit('SET_CURRENT_TAB',{model:'upi',currentTab:'lineCom'})
    },
    /**
     * @description 获取列表数据
     */
    async getList(){
      let CRITERION_LIST = getSearchValueList(this.conditions, this.$refs.filterForm.formData)
      let SHOW_COLUMN_LIST = this.tableColumnConfigList.map(item => item.tag).filter(item=>!TABLE_COLUMN_COMMON.includes(item))
      let EXTRA_COLUMN = ['CLIENT_ID','PALLET_ID']
      let params = {
        CRITERION_LIST: CRITERION_LIST,
        SHOW_COLUMN_LIST: uniqueArr([...SHOW_COLUMN_LIST,...EXTRA_COLUMN]),
        PAGE_NUM: this.$refs.configTable.page.currentPage,
        PAGE_SIZE: this.$refs.configTable.page.pageSize
      };
      this.exportParams = params;
      this.initFormData = this.$refs.filterForm.formData;
      const {status,data,message} = await Api.GetUPIHeaderList(params)
      if(status === 200){
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /**
     * @description 列表选中的数组
     */
    selectionChange(arr) {
      this.checkedList = arr
    },
    /**
     * @description 表单编辑时候的跳转
     */
    clientEdit({PALLET_ID,CLIENT_ID,LINE_ID}, type) {
      this.dialog = type;
      this.$router.push({
        path:'/UPIHeadDetail',
        query:{
          PALLET_ID,
          CLIENT_ID,
          LINE_ID,
          _current:this.dataTableName
        }
      })
    },
    /**
     * @description 清除表单和父子间的数据
     */
    dialogClosed(){
      this.dialog = null;
      this.injectData = {};
    },
    /**
     * @description 导出
     */
    handleExport() {
      this.exportDefine('UPI-Header 表', 'ExportUPIHeader');
    },
  }
}