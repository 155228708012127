<template>
  <el-tabs :value="activeName" @input="handleClick" type="card">
    <el-tab-pane :label="item.name" :name="item.code" :key="item.code" v-for="item in tabList">
      <component :is="activeName"></component>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
  import headCom from './tabs/head/head.vue'
  import lineCom from './tabs/line/line.vue'
  import {mapState} from 'vuex'

  export default {
    name:'UPI',
    components:{
      headCom,
      lineCom
    },
    data() {
      return {
        tabList:[
          {
            name:'头列表',
            code:'headCom'
          },
          {
            name:'行列表',
            code:'lineCom'
          }
        ]
      };
    },
    computed:{
      ...mapState({
        activeName:state=>state.headLineSearch.upi.currentTab,
      })
    },
    methods: {
      handleClick(tab) {
        this.$store.commit('SET_CURRENT_TAB',{model:'upi',currentTab:tab})
      }
    }
  };
</script>