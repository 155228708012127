import {YN, STATUS, CLIENT, ClientTimeUnit, PAGER} from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import Api from '@api';
import {PRE_ADVICE_MODE_OF_TRANSPORT, UPI_STATUS} from "../../../../constant";
import {GetPageTableColumnItem} from "../../../../api/country";
import {setSelectClientList, setSelectPalletList, setSelectSiteList} from "../../../../api/common";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "PALLET_ID",
      "label": "托盘号码",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入托盘号码",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NOTES",
      "label": "注释",
      "type": "textarea",
      "placeholder": "请输入描述",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "col": 2,
    }, {
      "key": "STATUS",
      "label": "状态",
      "type": "multiselect",
      "placeholder": "请选择状态",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": UPI_STATUS,
      "formatter": ({cellValue, row, column}) => {
        let res = UPI_STATUS.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "SITE_ID",
      "label": "站点",
      "type": "multiselect",
      "placeholder": "请选择站点",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      // "props": {
      //   "multiple": true,
      //   "collapse-tags": true
      // },
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE:{},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item=>{
            return{
              key:item.SITE_ID,
              label:item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "RECEIPT_ID",
      "label": "接受ID",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入接受ID",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "head",
    }, {
      "key": "SUPPLIER_ID",
      "label": "供应商",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "STATUS_REASON_CODE",
      "label": "状态原因",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入状态原因",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DUE_DSTAMP",
      "label": "到期日",
      "type": "date",
      "placeholder": "请选择到期日",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DUE_TIME",
      "label": "到期时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择到期时间",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "RECEIPT_DSTAMP",
      "label": "接收日期 ",
      "type": "date",
      "placeholder": "请选择接收日期 ",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "RECEIPT_TIME",
      "label": "接收时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择接收时间",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NUM_LINES",
      "label": "行数",
      "type": "numrange",
      "placeholder": "请选择行数",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATED_BY",
      "label": "创建人 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入创建人 ",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATION_DATE",
      "label": "创建日期 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入创建日期 ",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CREATION_TIME",
      "label": "创建时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请输入创建时间",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATED_BY",
      "label": "最后更新人",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入最后更新人",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATE_DATE",
      "label": "最后更新日期",
      "type": "date",
      "placeholder": "请选择最后更新日期",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LAST_UPDATE_TIME",
      "label": "最后更新时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择最后更新时间",
      "tabGroup": "mqs_upiheader_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "VOLUME",
      "label": "体积 ",
      "type": "numrange",
      "placeholder": "请选择体积 ",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "HEIGHT",
      "label": "高度",
      "type": "numrange",
      "placeholder": "请选择高度",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "WEIGHT",
      "label": "重量 ",
      "type": "numrange",
      "placeholder": "请选择重量 ",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "WIDTH",
      "label": "宽度 ",
      "type": "numrange",
      "placeholder": "请选择宽度 ",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DEPTH",
      "label": "深度",
      "type": "numrange",
      "placeholder": "请选择深度",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DISALLOW_MERGE_RULES",
      "label": "忽略合并规则",
      "type": "select",
      "placeholder": "请选择忽略合并规则",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ASN_ID",
      "label": "预到货通知单号",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入预到货通知单号",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_WS2PC_ID",
      "label": "Web Service 上传",
      "type": "numrange",
      "placeholder": "请选择Web Service 上传",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_FILENAME",
      "label": "上传文件",
      "type": "multiselect",
      "placeholder": "请选择上传文件",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": []
    }, {
      "key": "UPLOADED",
      "label": "上传文件名",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入上传文件名",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_DSTAMP",
      "label": "上传日期",
      "type": "date",
      "placeholder": "请选择上传日期",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_TIME",
      "label": "上传时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择上传时间",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CARRIER_NAME",
      "label": "承运商名称 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入承运商名称 ",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TOD",
      "label": "交付方式",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入交付方式",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "TOD_PLACE",
      "label": "交付地点",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入交付地点",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "MODE_OF_TRANSPORT",
      "label": "运输方式",
      "type": "multiselect",
      "placeholder": "请选择运输方式",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": PRE_ADVICE_MODE_OF_TRANSPORT,
      "formatter": ({cellValue, row, column}) => {
        let res = PRE_ADVICE_MODE_OF_TRANSPORT.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CARRIER_REFERENCE",
      "label": "承运商参考 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入承运商参考 ",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "YARD_CONTAINER_ID",
      "label": "集装箱号",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入集装箱号",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "YARD_CONTAINER_TYPE",
      "label": "集装箱类型",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入集装箱类型",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_CONSIGNMENT_ID",
      "label": "C&E 发货ID",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入C&E 发货ID",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_INVOICE_NUMBER",
      "label": "C&E 发票号",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入C&E 发票号",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "RECEIPT_CLOSED",
      "label": "关闭收货单",
      "type": "select",
      "placeholder": "请选择关闭收货单",
      "tabGroup": "mqs_upiheader_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "CROSS_DOCK",
      "label": "越库",
      "type": "select",
      "placeholder": "请选择越库",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "TO_SITE_ID",
      "label": "到达站点",
      "type": "multiselect",
      "placeholder": "请选择到达站点",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectSiteList({
          SITE:{},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item=>{
            return{
              key:item.SITE_ID,
              label:item.SITE_ID
            }
          })
        }
      },
    }, {
      "key": "SHIP_DOCK",
      "label": "发货月台",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入发货月台",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CONSIGNMENT",
      "label": "托运",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入托运",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CUSTOMER_ID",
      "label": "送货客户",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入送货客户",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "LOAD_SEQUENCE",
      "label": "装栽次序",
      "type": "numrange",
      "placeholder": "请选择装栽次序",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CROSS_DOCK_TO_SITE",
      "label": "在目的地交叉转运",
      "type": "select",
      "placeholder": "请选择在目的地交叉转运",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "ROUTE_ID",
      "label": "路线ID",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入路线ID",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CROSSDOCK_PROGRESS",
      "label": "交叉货站进程 ",
      "type": "multiselect",
      "placeholder": "请选择交叉货站进程 ",
      "tabGroup": "mqs_upiheader_tab2",
      "tabGroupName": "越库",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": []
    }, {
      "key": "SUP_NAME",
      "label": "供应商名称 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商名称 ",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_CONTACT",
      "label": "供应商联系人",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商联系人",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_ADDRESS1",
      "label": "供应商地址1",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商地址1",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_CONTACT_PHONE",
      "label": "供应商电话",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商电话",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_ADDRESS2",
      "label": "供应商地址2",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商地址2",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_CONTACT_FAX",
      "label": "供应商传真",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商传真",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_TOWN",
      "label": "供应商城镇 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商城镇",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_CONTACT_EMAIL",
      "label": "供应商e-mail",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入 供应商e-mail",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_COUNTY",
      "label": "供应商县市",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商县市",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_CONTACT_MOBILE",
      "label": "供应商手机",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商手机",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_POSTCODE",
      "label": "供应商邮政编码",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入供应商邮政编码",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "VAT_NUMBER",
      "label": "Vat Number",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入Vat Number",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "SUP_COUNTRY",
      "label": "供应商所在国家",
      "type": "multiselect",
      "placeholder": "请选择供应商所在国家",
      "tabGroup": "mqs_upiheader_tab3",
      "tabGroupName": "供应商地址",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await GetPageTableColumnItem({
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item=>{
            return{
              key:item.ISO3_ID,
              label:item.ISO3_ID
            }
          })
        }
      },
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "用户自定义类型 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 1 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "用户自定义类型 2 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 2 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "用户自定义类型 3 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 3 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "用户自定义类型 4 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 4 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "用户自定义类型 5 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 5 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "用户自定义类型 6 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 6 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "用户自定义类型 7 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 7 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "用户自定义类型 8 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 8 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "用户自定义的检查1 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查1 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "用户自定义的检查2 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查2 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "用户自定义日期 1 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "用户自定义时间 1 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 1 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "用户自定义时间 2 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 2",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "用户自定义时间 3",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 3",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_4",
      "label": "用户自定义时间 4",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 4",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "用户自定义号码 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 1 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 4 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "user_def_upih_tap0",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "ARCHIVED",
      "label": "存档",
      "type": "select",
      "placeholder": "请选择存档",
      "tabGroupName": "空",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "BOOKREF_ID",
      "label": "预约号",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入预约号",
      "tabGroupName": "空",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DELIVER_BY_DATE",
      "label": "预计交货日期",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入预计交货日期",
      "tabGroupName": "空",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "DIARY_DSTAMP",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "NO_COMPLETION_EMAIL",
      "label": "",
      "type": "select",
      "placeholder": "请选择",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "OLD_CROSS_DOCK",
      "label": "旧越库",
      "type": "select",
      "placeholder": "请选择旧越库",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "OLD_CROSS_DOCK_TO_SITE",
      "label": "旧目的站点越库",
      "type": "select",
      "placeholder": "请选择旧目的站点越库",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "OLD_ROUTE_ID",
      "label": "旧路线ID",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入旧路线ID",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "OLD_SHIP_DOCK",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "OLD_SITE_ID",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "OLD_TO_SITE_ID",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "OLD_XDOCK_OUTBOUND_UPI",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "PALLET_CONFIG",
      "label": "托盘类型 ",
      "type": "multiselect",
      "placeholder": "请选择托盘类型 ",
      "tabGroupName": "空",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": async () => {
        let {status, data: {list}, message} = await setSelectPalletList({
          PALLET_CONFIG: {},
          ...defaultPaginationInfo
        });

        if (Number(status) === 200) {
          return list.map(item=>{
            return{
              key:item.CONFIG_ID,
              label:item.CONFIG_ID
            }
          })
        }
      },
    }, {
      "key": "PRIORITY",
      "label": "优先级 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入优先级 ",
      "tabGroupName": "空",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    },  {
      "key": "UPLOADED_HEADER_KEY",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "UPLOADED_VVIEW",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "XDOCK_OUTBOUND_UPI",
      "label": "出库UPI ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入出库UPI ",
      "tabGroupName": "空",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["dataInfo"],
      "checked": false,
      defaultUpper:true,
      "sheetName": "head",
    }]
  }
}