import {YN, STATUS, CLIENT, ClientTimeUnit, PAGER} from '@constant';
import {createTimeoutPromise, dateFormat} from "@utils/utils";
import Api from '@api';
import {setSelectClientList, setSelectOwnerList} from "../../../../api/common";

const defaultPaginationInfo = {
  PAGE_NUM: PAGER.MIN_PAGE_NUM,
  PAGE_SIZE: PAGER.MAX_PAGE_SIZE
}

export const getFormConfig = (vm) => {
  return {
    filterFormConfig: [{
      "key": "PALLET_ID",
      "label": "托盘号",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入托盘号",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LINE_ID",
      "label": "行号",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入行号",
      "tabGroup": "THead",
      "tabGroupName": "表头",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "SKU_ID",
      "label": "SKU",
      "type": "magnifier",
      "placeholder": "请输入SKU",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CLIENT_ID",
      "label": "客户端",
      "type": "magnifier",
      "placeholder": "请选择客户端",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      defaultUpper:true,
      "checked": false
    }, {
      "key": "OWNER_ID",
      "label": "所有者",
      "type": "magnifier",
      "placeholder": "请选择所有者",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "_DESCRIPTION_",
      "label": "描述",
      "type": "textarea",
      "placeholder": "",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": [
        "dataInfo"
      ],
      "col": "2",
    }, {
      "key": "QTY_DUE",
      "label": "应付数",
      "type": "numrange",
      "placeholder": "请选择应付数",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "QTY_RECEIVED",
      "label": "接收数量 ",
      "type": "numrange",
      "placeholder": "请选择接收数量 ",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "QTY_ALLOCATED",
      "label": "分配数量",
      "type": "numrange",
      "placeholder": "请选择分配数量",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TRACKING_LEVEL",
      "label": "跟踪级别 ",
      "type": "multiselect",
      "placeholder": "请选择跟踪级别 ",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": []
    }, {
      "key": "CONFIG_ID",
      "label": "打包配置",
      "type": "multiselect",
      "placeholder": "请选择打包配置",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false,
      "options": []
    }, {
      "key": "TAG_ID",
      "label": "标记ID",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入标记ID",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SPEC_CODE",
      "label": "规格代码 ",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入规格代码 ",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "SUPPLIER_ID",
      "label": "供应商",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入供应商",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "HOST_PALLET_ID",
      "label": "主托盘ID",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入主托盘ID",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "HOST_LINE_ID",
      "label": "主行号",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入主行号",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "RECEIPT_DSTAMP",
      "label": "接收日期 ",
      "type": "date",
      "placeholder": "请选择接收日期 ",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "RECEIPT_TIME",
      "label": "接收时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择接收时间",
      "tabGroup": "mqs_upiline_tab0",
      "tabGroupName": "综合",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "ORIGIN_ID",
      "label": "产地",
      "type": "magnifier",
      "placeholder": "请输入产地",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CONDITION_ID",
      "label": "条件",
      "type": "magnifier",
      "placeholder": "请输入条件",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LOCK_CODE",
      "label": "锁定 代码 ",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入锁定 代码 ",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MANUF_DSTAMP",
      "label": "生产日期 ",
      "type": "date",
      "placeholder": "请选择生产日期 ",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "MANUF_TIME",
      "label": "生产时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择生产时间",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "DISALLOW_MERGE_RULES",
      "label": "忽略合并规则",
      "type": "select",
      "placeholder": "请选择忽略合并规则",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EXPIRY_DSTAMP",
      "label": "失效日期",
      "type": "date",
      "placeholder": "请输入失效日期",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "EXPIRY_TIME",
      "label": "失效时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请输入失效时间",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "BATCH_ID",
      "label": "批号",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入批号",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PRE_ADVICE_ID",
      "label": "预通知号",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入预通知号",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PRE_ADVICE_LINE_ID",
      "label": "预通知行号",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入预通知行号",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TRAILER_ID",
      "label": "拖车",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入拖车",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "TRANSFER_ORDER_ID",
      "label": "传送订单",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入传送订单",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LINE_ID_LINK",
      "label": "主订单",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入主订单",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false
    }, {
      "key": "ORDER_ID_LINK",
      "label": "主订单行",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入主订单行",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CREATED_BY",
      "label": "创建人 ",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入创建人 ",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CREATION_DATE",
      "label": "创建日期 ",
      "type": "date",
      "placeholder": "请选择创建日期 ",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "CREATION_TIME",
      "label": "创建时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择创建时间",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "LAST_UPDATED_BY",
      "label": "最后更新人",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入最后更新人",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LAST_UPDATE_DATE",
      "label": "最后更新日期",
      "type": "date",
      "placeholder": "请选择最后更新日期",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "LAST_UPDATE_TIME",
      "label": "最后更新时间",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择最后更新时间",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["dataInfo"],
      "checked": false
    }, {
      "key": "PRODUCT_PRICE",
      "label": "价格",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入价格",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PRODUCT_CURRENCY",
      "label": "币种",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入币种",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "PRE_RECEIVED",
      "label": "预接收",
      "type": "select",
      "placeholder": "请选择预接收",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "PRE_RECEIPT_TAG_PRINTED",
      "label": "预接收打印标签",
      "type": "select",
      "placeholder": "请选择预接收打印标签",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false,
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "EXPECTED_NET_WEIGHT",
      "label": "预计净重",
      "type": "numrange",
      "placeholder": "请选择预计净重",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "EXPECTED_GROSS_WEIGHT",
      "label": "预计毛重",
      "type": "numrange",
      "placeholder": "请选择预计毛重",
      "tabGroup": "mqs_upiline_tab1",
      "tabGroupName": "杂项",
      "showBlock": ["all"],
      "checked": false
    }, {
      "key": "USER_DEF_TYPE_1",
      "label": "用户自定义类型 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 1 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_2",
      "label": "用户自定义类型 2 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 2 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_3",
      "label": "用户自定义类型 3 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 3 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_4",
      "label": "用户自定义类型 4 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 4 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_5",
      "label": "用户自定义类型 5 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 5 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_6",
      "label": "用户自定义类型 6 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 6 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_7",
      "label": "用户自定义类型 7 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 7 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TYPE_8",
      "label": "用户自定义类型 8 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义类型 8 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_CHK_1",
      "label": "用户自定义的检查1 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查1 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_2",
      "label": "用户自定义的检查2 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查2 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_3",
      "label": "用户自定义的检查3 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查3 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_CHK_4",
      "label": "用户自定义的检查4 ",
      "type": "select",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义的检查4 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head",
      "options": YN,
      "formatter": ({cellValue, row, column}) => {
        let res = YN.filter(item => {
          return item.key === cellValue
        })
        return res[0] ? res[0].label : ''
      }
    }, {
      "key": "USER_DEF_DATE_1",
      "label": "用户自定义日期 1 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 1 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_1",
      "label": "用户自定义时间 1 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 1 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_2",
      "label": "用户自定义日期 2 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 2 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_2",
      "label": "用户自定义时间 2 ",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 2",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_3",
      "label": "用户自定义日期 3 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 3 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_3",
      "label": "用户自定义时间 3",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 3",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_DATE_4",
      "label": "用户自定义日期 4 ",
      "type": "date",
      "placeholder": "请选择用户自定义日期 4 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_TIME_4",
      "label": "用户自定义时间 4",
      "type": "time",
      "props": {
        "value-format": "HH:mm:ss"
      },
      "placeholder": "请选择用户自定义时间 4",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["all"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_1",
      "label": "用户自定义号码 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 1 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_2",
      "label": "用户自定义号码 2",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 2",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_3",
      "label": "用户自定义号码 3 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 3 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NUM_4",
      "label": "用户自定义号码 4 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义号码 4 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_1",
      "label": "用户自定义注释 1 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义注释 1 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "USER_DEF_NOTE_2",
      "label": "用户自定义注释 2 ",
      "type": "magnifier",
      "tableName":'UPI_HEADER',
      "placeholder": "请输入用户自定义注释 2 ",
      "tabGroup": "User_Defined _ Setting_1",
      "tabGroupName": "用户自定义的_设置1",
      "showBlock": ["dataInfo"],
      "checked": false,
      "sheetName": "head"
    }, {
      "key": "CE_CONSIGNMENT_ID",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_COO",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_INVOICE_NUMBER",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_LINK",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false
    }, {
      "key": "CE_UNDER_BOND",
      "label": "",
      "type": "magnifier",
      "tableName":'UPI_LINE',
      "placeholder": "请输入",
      "tabGroupName": "空",
      "showBlock": [],
      "checked": false
    }]
  }
}